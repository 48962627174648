<template>
    <Modal :expand="true">
        <h1>{{ headline }}</h1>
        <Form @submit="submit" @attempt="attempt">
            <fieldset>
                <legend>Virksomhedsoplysninger</legend>

                <Input type="text" placeholder="Virksomhed*" v-model="fields.company.name"
                       :required="true" tooltip=" Indtast virksomhedens navn"/>

                <Input type="text" placeholder="Adresse*" v-model="fields.company.address"
                       :required="true" tooltip="Adresse for virksomheden"/>

                <Input type="number" placeholder="Postnummer*" v-model="fields.company.zipcode"
                       :required="true"/>
                <Input type="text" placeholder="By*" v-model="fields.company.city"
                       :required="true"/>
            </fieldset>

            <fieldset>
                <legend>Kontaktperson</legend>
                <Input type="text" placeholder="Navn*" v-model="fields.contact.name"
                       :required="true" tooltip="Navn på kontaktperson for virksomheden"/>

                <Input type="email" placeholder="E-mail*" v-model="fields.contact.email"
                       :required="true" tooltip=" E-mail til kontaktperson"/>

                <Input type="number" placeholder="Telefon" v-model="fields.contact.phone"
                       pattern="[0-9]{8}" maxlength="8"
                       tooltip="Telefonnummer til kontaktperson"/>
            </fieldset>

            <fieldset>
                <legend>Aftalens indhold</legend>
                <Input type="text" placeholder="Entreprise*" v-model="fields.agreement.subcontract"
                       :required="true" tooltip="Fx. Hovedentreprise, tømrer, VVS, EL mm."/>

                <Input type="text" placeholder="Beskrivelse af ydelse*"
                       v-model="fields.agreement.description"
                       :required="true" tooltip="Kort beskrivelse af det aftalte omfang"/>

                <Select placeholder="Prisaftale" :required="true"
                        v-model="fields.agreement.payment.type">
                    <option value="fixed">Fast pris (anbefales)</option>
                    <option value="estimate">Overslag +/- 15%</option>
                    <option value="hourly">Efter regning (timepris)</option>
                </Select>

                <Price :placeholder="pricePlaceholder" v-model="fields.agreement.payment.price"
                       :required="true" :tooltip="priceTooltip"/>
            </fieldset>
            <fieldset>
                <legend class="group">
                    <span>Kontrakt</span>
                    <Checkbox v-if="!edit" v-model="uploadContract">Brug egen kontrakt</Checkbox>
                </legend>
                <ViewFile v-if="!uploadContract && !edit" name="standardkontrakt.pdf" path="/files/standardkontrakt.pdf" :local="true" style="margin-top: 0;"/>
                <Upload v-if="uploadContract || edit" label="Vedhæft kontrakt" v-model="fields.agreement.files.standard"/>

                <legend style="margin-top: 3.7rem;">Andre bilag</legend>
                <!--<template v-if="edit">
                    <ViewFile v-if="fields.agreement.files.offer" v-bind="fields.agreement.files.offer"/>
                    <ViewFile v-if="fields.agreement.files.appendix" v-bind="fields.agreement.files.appendix"/>
                    <ViewFile v-if="fields.agreement.files.standard" v-bind="fields.agreement.files.standard"/>
                </template>-->
                <Upload label="Vedhæft tilbud" v-model="fields.agreement.files.offer"/>
                <Upload label="Vedhæft grundlag for tilbud"
                        v-model="fields.agreement.files.appendix"
                        tooltip="F.eks. tegninger eller beskrivelser"/>
            </fieldset>
            <fieldset class="double">
                <Checkbox v-if="!uploadContract" v-model="acceptTerms" :expand="true" :required="true">Jeg accepterer
                    indholdet i
                    standardkontrakten som medsendes som en betingelse for aftalens accept til
                    virksomheden.
                </Checkbox>
            </fieldset>
            <ActionButton :icon="['check', 'times']" :float="true" :focus-icon="focusIcon"/>
            <OverlayButtons :show="showOverlayButton">
                <ActionButton icon="paperplane" label="Send kontrakt" :small="true"
                              :secondary="true" @click="send"/>
                <ActionButton icon="save" label="Gem som kladde" :small="true" :secondary="true"
                              @click="save"/>
            </OverlayButtons>
        </Form>
    </Modal>
</template>

<script>
import Modal from '@/components/Modal.vue';
import ActionButton from '@/components/buttons/ActionButton.vue';
import Select from '@/components/inputs/Select.vue';
import Upload from '@/components/inputs/Upload.vue';
import Price from '@/components/inputs/Price.vue';
import Form from '@/components/Form.vue';
import Input from '@/components/inputs/Input.vue';
import { uploadFile, uploadLocalFile } from '@/utils/firebase';
import { contractsCollection, projectsCollection, usersCollection } from '@/utils/collections';
import useState from '@/store';
import methods from '@/utils/methods';
import OverlayButtons from '@/components/buttons/OverlayButtons.vue';
import Checkbox from '@/components/inputs/Checkbox.vue';
import ViewFile from '@/components/ViewFile.vue';
import { useRoute } from 'vue-router';

export default {
    name: 'Timeline',
    components: {
        Modal,
        ActionButton,
        Select,
        Upload,
        Price,
        Form,
        Input,
        OverlayButtons,
        Checkbox,
        ViewFile,
    },
    mixins: [methods],
    setup() {
        const { project, user, setModalData } = useState();
        const route = useRoute();

        const { id } = route.params;
        const edit = !!id;
        const headline = edit ? 'Rediger kontrakt' : 'Tilføj kontrakt';

        return { project, user, edit, headline, id, setModalData };
    },
    data() {
        return {
            fields: {
                company: {
                    name: null,
                    address: null,
                    zipcode: null,
                    city: null,
                },
                contact: {
                    name: null,
                    email: null,
                    phone: null,
                },
                agreement: {
                    subcontract: null,
                    description: null,
                    payment: {
                        type: '',
                        price: 0,
                    },
                    files: {
                        offer: null,
                        appendix: null,
                        standard: {
                            name: 'standardkontrakt.pdf',
                            path: '/files/standardkontrakt.pdf',
                            local: true,
                        },
                    },
                },
            },
            uploadContract: false,
            acceptTerms: false,
            validated: false,
            showOverlayButton: false,
        };
    },
    computed: {
        pricePlaceholder() {
            const placeholders = {
                fixed: 'Pris inkl. moms*',
                estimate: 'Pris inkl. moms*',
                hourly: 'Timepris inkl. moms*',
            };

            // TODO: Lave dette til et map object i stedet.
            return placeholders[this.fields.agreement.payment.type] || Object.values(placeholders)[0];
        },
        priceTooltip() {
            const tooltips = {
                fixed: 'Kan ikke ændres uden tillægsaftale',
                estimate: 'Kan variere de 15% fra overslag',
                hourly: 'Timepris der afregnes efter forbrugte timer',
            };

            // TODO: Lave dette til et map object i stedet.
            return tooltips[this.fields.agreement.payment.type] || Object.values(tooltips)[0];
        },
        focusIcon() {
            return Number(this.showOverlayButton);
        },
    },
    async mounted() {
        if (this.edit) await this.setupData();

        let user = await usersCollection.doc(this.user.id).get();
        let project = await projectsCollection().doc(this.project.id).get();

        user = user.data();
        project = project.data();

        this.$data.fields.builder = {
            name: `${ user.firstname } ${ user.lastname }`,
            email: user.email,
        };

        this.$data.fields.project = {
            address: project.address,
            from: project.from.toDate(),
            to: project.to.toDate(),
        };
    },
    methods: {
        attempt() {
            this.validated = false;
            this.showOverlayButton = false;
        },
        submit() {
            this.validated = true;
            this.showOverlayButton = !this.showOverlayButton && this.validated;
        },
        async create() {
            try {
                this.fields.created = new Date();
                const data = this.removeNullValues(this.$data.fields);
                const { offer, appendix, standard } = data.agreement.files;

                if (offer) data.agreement.files.offer = uploadFile(offer, offer.name, this.project.id);
                if (appendix) data.agreement.files.appendix = uploadFile(appendix, appendix.name, this.project.id);

                if (!this.uploadContract) {
                    data.agreement.files.standard = await uploadLocalFile('/files/standardkontrakt.pdf', 'standardkontrakt.pdf', this.project.id);
                } else if (standard) {
                    data.agreement.files.standard = uploadFile(standard, standard.name, this.project.id);
                }

                const contract = await contractsCollection().add(data);
                return { id: contract.id, ...data };
            } catch (err) {
                console.log(err);
            }

            return null;
        },
        async send() {
            const contract = this.edit ? await this.update() : await this.create();
            await this.setModalData(contract);
            await this.$router.push({
                name: 'view-contract',
                params: { id: contract.id },
                query: { action: 'send' },
            });
        },
        async save() {
            if (this.edit) await this.update();
            else await this.create();
            await this.$router.push({ name: 'contracts' });
        },
        async update() {
            try {
                const data = this.removeNullValues(this.fields);
                const { offer, appendix, standard } = data.agreement.files;

                data.agreement.files.offer = offer ? this.uploadOrUseLocalFile(offer) : null;
                data.agreement.files.appendix = appendix ? this.uploadOrUseLocalFile(appendix) : null;
                data.agreement.files.standard = standard ? this.uploadOrUseLocalFile(standard) : null;

                await contractsCollection().doc(this.id).update(data);
                return data;
            } catch (err) {
                console.log(err);
            }

            return null;
        },
        async setupData() {
            try {
                const contract = await contractsCollection().doc(this.id).get();
                const data = contract.data();
                this.fields = { ...this.fields, ...data };
                this.acceptTerms = true;
                this.uploadContract = true;
            } catch (err) {
                console.log(err);
            }
        },
    },
};
</script>

<style scoped lang="less">
h2 {
    font-size: 2.4rem;
    font-weight: var(--semibold-weight);
    margin: 0 0 3.5rem;
}

p {
    margin: 1.3rem 0 0;
}
</style>
